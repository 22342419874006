import React from 'react'
import DropdownField from 'core/components/validatedForm/DropdownField'
import TextField from 'core/components/validatedForm/TextField'
import AsyncDropdown from 'core/elements/dropdown/AsyncDropdown'
import { requiredValidator, validIpv4Validator } from 'core/utils/fieldValidators'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  bulletList: {
    paddingInlineStart: 16,
    marginBlockStart: 0,
    marginBlockEnd: 0,
  },
}))

const ipValidations = [requiredValidator, validIpv4Validator]

const interfaceDetectionOptions = [
  { label: 'First Found', value: 'FirstFound' },
  {
    label: 'Interface Name',
    value: 'InterfaceName',
  },
  { label: 'Interface Reachable IP', value: 'InterfaceReachableIP' },
]

export default function CustomInterfaceFields({ values, setValues }) {
  const classes = useStyles()
  return (
    <>
      <DropdownField
        DropdownComponent={AsyncDropdown}
        id="interfaceDetectionMethod"
        value={values?.interfaceDetectionMethod}
        onChange={(value) => setValues({ interfaceDetectionMethod: value })}
        label="Interface Detection Method"
        items={interfaceDetectionOptions}
        tooltip={
          <ul className={classes.bulletList}>
            <li>First found: First valid IP address on the first interface.</li>
            <li>
              Interface Name: Name of the interface to be used by the node to reach a particular IP
              or domain.
            </li>
            <li>
              Interface Reachable IP: Address used by the node to reach a particular IP or domain.
            </li>
          </ul>
        }
        selectFirst
        required
      />
      {values?.interfaceDetectionMethod === 'InterfaceName' && (
        <TextField
          id="customInterfaceName"
          value={values?.customInterfaceName}
          onChange={(value) => setValues({ customInterfaceName: value })}
          label="Interface Name"
          placeholder="Enter name..."
          required
        />
      )}
      {values?.interfaceDetectionMethod === 'InterfaceReachableIP' && (
        <TextField
          id="customInterfaceIP"
          value={values?.customInterfaceIP}
          onChange={(value) => setValues({ customInterfaceIP: value })}
          label="IP"
          placeholder="Enter IP"
          validations={ipValidations}
          required
        />
      )}
    </>
  )
}
